export const PAM_ENVIRONMENTS = ["develop", "qa", "production"];

export const isProduction = () =>
  process.env.PAM_ENVIRONMENT !== undefined &&
  (process.env.PAM_ENVIRONMENT === "production-green" || process.env.PAM_ENVIRONMENT === "production-blue");

export function isPamEnvironment(environmentName: string): boolean {
  return PAM_ENVIRONMENTS.some((nonStandardEnvironment) => environmentName.startsWith(nonStandardEnvironment));
}

export function isTestingEnvironment(environmentName: string): boolean {
  return environmentName.startsWith("intg-");
}

export function getPamEnvironmentNameWithoutStack(environmentName: string): string {
  // Integration tests are uniquely generated and can return immediately as they don't have a stack name.
  if (isTestingEnvironment(environmentName)) {
    return environmentName;
  }

  for (const pamEnvironment of PAM_ENVIRONMENTS) {
    if (environmentName.startsWith(pamEnvironment)) {
      return pamEnvironment;
    }
  }

  // Personal stacks
  return environmentName;
}

export const getEnvVariableOrThrow = (name: string): string => {
  const value = process.env[name];
  if (value === undefined) {
    throw new Error(`Missing environment variable: ${name}`);
  }

  return value;
};
