import pino from "pino";
import { isProduction } from "@pam/common/environment";

// checks for the existence of a 'msg' arg in the first object passed, passes it as last arg
// so that browser console logs make sense
function surfaceMsgString(args: Parameters<Console["log"]>) {
  const msg = (
    args[0] as {
      msg: string;
    }
  ).msg;
  if (msg) args.unshift(msg);
  return args;
}

// no client logging in production
// we could just return an empty function but we want it to be testable
export const browserLogFunctions = {
  info: (...args: Parameters<Console["info"]>) => {
    if (!isProduction()) console.info(...surfaceMsgString(args));
  },
  warn: (...args: Parameters<Console["warn"]>) => {
    if (!isProduction()) console.warn(...surfaceMsgString(args));
  },
  error: (...args: Parameters<Console["error"]>) => {
    if (!isProduction()) console.error(...surfaceMsgString(args));
  },
  debug: (...args: Parameters<Console["debug"]>) => {
    if (!isProduction()) console.debug(...surfaceMsgString(args));
  },
  trace: (...args: Parameters<Console["trace"]>) => {
    if (!isProduction()) console.trace(...surfaceMsgString(args));
  },
};

const baseLogger = pino({
  level: "debug",
  redact: [
    '["authorization"]',
    '[*]["authorization"]',
    '[*][*]["authorization"]',
    '[*][*][*]["authorization"]',
    '["x-api-key"]',
    '[*]["x-api-key"]',
    '[*][*]["x-api-key"]',
    '[*][*][*]["x-api-key"]',
    '["password"]',
    '[*]["password"]',
    '[*][*]["password"]',
    '[*][*][*]["password"]',
  ],
  serializers: { error: pino.stdSerializers.err },
  browser: { write: browserLogFunctions },
});

export const log = baseLogger;
